.carousel-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 80vh; /* Limit the max height to 60% of the viewport height */
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 80vh; /* Ensures images don't exceed 60% of the viewport height */
}
